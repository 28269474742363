import clsx from 'clsx';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import styles from './dropdown.module.scss';

export default function Component(props) {
    const { className, textSelector, items, updateDropdown, ariaLabel } = props;

    const [openDropdown, setOpenDropdown] = useState(false);

    const [label, setLabel] = useState(textSelector);

    const refDropDown = useRef();
    const refDropper = useRef();

    const stylesDropdown = clsx({
        [styles.wrapper]: true,
        [styles.open]: openDropdown,
        [styles[className]]: !__isEmpty(className)
    });

    const itemsDropdown = () => {
        return items.map((item) => {
            const label = item.desc || item.titol;
            const stylesItem = clsx({
                [styles.item]: true,
                [styles.selected]: label === textSelector
            });
            return (
                <li
                    key={item.id}
                    onClick={() => {
                        updateDropdown(item);
                        setLabel(label);
                    }}
                    className={stylesItem}
                >
                    {label}
                </li>
            );
        });
    };

    useEffect(() => {
        setLabel(textSelector);
    }, [textSelector]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refDropDown?.current?.contains(event.target)) {
                setOpenDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside, { passive: true });
        document.addEventListener('touchstart', handleClickOutside, { passive: true });

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [refDropDown]);

    useEffect(() => {
        if (openDropdown) {
            refDropper.current.setAttribute('aria-expanded', true);
        } else {
            refDropper.current.setAttribute('aria-expanded', false);
        }
    }, [openDropdown]);

    return (
        <div className={stylesDropdown} ref={refDropDown}>
            <button onClick={() => setOpenDropdown(!openDropdown)} className={styles.dropdown} aria-label={ariaLabel}>
                <div className={styles.dropdown_text}>{label}</div>
                <Icona className={styles.drowpdown_icona} icona='fletxa_simple_avall' alt='fletxa desplegar' />
            </button>
            <div className={styles.dropWrapper} ref={refDropper} onClick={() => setOpenDropdown(false)}>
                <ul data-testid='dropdown'>{itemsDropdown()}</ul>
            </div>
        </div>
    );
}

Component.defaultProps = {
    ariaLabel: 'Obrir desplegable'
};
