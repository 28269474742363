import { replaceClientDataUrlRouteQueryVariables } from '@portal-internet/core';
import clsx from 'clsx';
import BlocDeContinguts from 'components/3cat/BlocDeContinguts';
import Dropdown from 'components/3cat/Dropdown';
import { types } from 'components/3cat/Formats';
import Mosaic from 'components/3cat/Mosaic/Mosaic';
import Slider from 'components/3cat/Slider';
import { useData } from 'hooks/useData';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import BotoMes from './BotoMes';
import TitolFilter from './TitolFilter';
import styles from './filtermosaic.module.scss';

const getOrdre = (reglesTemporada, temporadaSeleccionada, numTemporades) => {
    if (numTemporades) {
        const ordreTemporada =
            reglesTemporada === 'EMISSIO' && temporadaSeleccionada === 'PUTEMP_' + numTemporades ? '-capitol' : 'capitol';
        return [{ ordre: ordreTemporada }];
    } else {
        return [{ origen: 'auto' }, { perfil: 'pc' }];
    }
};

const getFinalUrl = (urlBase, paramsFilter) => {
    let params = '';
    paramsFilter.map(
        (filter) =>
            (params += Object.entries(filter).map(([key, value], i) => {
                return '&' + key + '=' + value;
            }))
    );
    return urlBase.replace('__PARAMSVALUES__', params);
};
export default function FilterMosaic(props) {
    const {
        componentFilterName,
        tipus,
        keyFilter,
        valueFilter,
        filterDefault,
        filterDefaultId,
        items,
        url,
        reglesTemporada,
        nomPrograma,
        routerQuery,
        estilSlider,
        estilMosaic,
        estilTitol,
        concatFilterDefault,
        showTitol,
        loadMoreItemsScroll,
        numElementsDisplay,
        noId
    } = props;

    const { isLoading, data: itemsVideos, paginacio, getDades } = useData();
    const [divResultatsVideos, setDivResultatsVideos] = useState(<></>);
    const numTemporades = !__isEmpty(items) && tipus === 'temporades' ? items?.substring(7) : null;
    const temporadaActive = reglesTemporada !== 'ARXIU' ? items : 'PUTEMP_1';
    const [active, setActive] = useState(tipus === 'temporades' ? temporadaActive : null);
    const [refresh, setRefresh] = useState(true);
    const paramsFilterDefault = {};
    const paramsFilters = [];

    if (filterDefault) {
        paramsFilterDefault[filterDefault] = filterDefaultId || routerQuery.id;
        noId ? paramsFilters.push({ no_id: noId }) : '';
        paramsFilters.push(paramsFilterDefault);
    }

    const _getFiltersInicials = () => {
        if (tipus === 'temporades') {
            const filtreOrdre = getOrdre(reglesTemporada, active, numTemporades);
            filtreOrdre?.map((filtre) => paramsFilters.push(filtre));
            if (!__isEmpty(items)) {
                paramsFilters.push({ temporada: 'PUTEMP_' + (reglesTemporada === 'ARXIU' ? 1 : items?.substring(7)) });
            }
        }
        return paramsFilters;
    };

    const [paramsFilter, setParamsFilter] = useState(_getFiltersInicials());
    const urlBase = replaceClientDataUrlRouteQueryVariables(url, routerQuery) + '__PARAMSVALUES__';

    useEffect(() => {
        setParamsFilter(_getFiltersInicials());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerQuery.id]);

    useEffect(() => {
        setDivResultatsVideos(<></>);
        let filterTemporada = paramsFilter?.find((element) => element.temporada);
        if (filterTemporada !== undefined) {
            setActive(filterTemporada.temporada);
        }
        getDades(getFinalUrl(urlBase, paramsFilter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsFilter]);

    useEffect(() => {
        if (itemsVideos.length === 0 && isLoading && refresh) {
            setRefresh(false);
            const urlBase =
                replaceClientDataUrlRouteQueryVariables(url.replace('&no_id={{routerQuery.id}}', ''), routerQuery) +
                '__PARAMSVALUES__';
            getDades(getFinalUrl(urlBase, paramsFilter));
        } else if (loadMoreItemsScroll) {
            setDivResultatsVideos(
                <BlocDeContinguts
                    tipus='MOSAIC'
                    items={itemsVideos}
                    paginacio={paginacio}
                    format={types.KEYFRAME}
                    url={getFinalUrl(urlBase, paramsFilter)}
                    barraprogres={false}
                    hasNextPage={false}
                    numElementsDisplay={numElementsDisplay}
                />
            );
        } else {
            setDivResultatsVideos(<Mosaic items={itemsVideos} format={types.KEYFRAME} titol={showTitol?.titol} />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsVideos]);

    const handlerFilter = (value) => {
        const filters = [];
        if (concatFilterDefault) filters.push(paramsFilterDefault);
        filters.push(value.filter);
        setParamsFilter(filters);
    };

    const _getListTemporades = () => {
        const temporades = [];
        for (let i = 1; i <= items?.substring(7); i++) {
            temporades.push({
                id: 'PUTEMP_' + i,
                titol: 'Temporada ' + i,
                desc: 'Temporada ' + i,
                filter: { temporada: 'PUTEMP_' + i },
                temporada: i
            });
        }
        return reglesTemporada === 'ARXIU' ? temporades : temporades.reverse();
    };

    const _getItemsSeccions = () => {
        return items.map((item) => {
            const obj = {};
            obj[keyFilter] = item[valueFilter];
            item.filter = obj;
            return item;
        });
    };

    const updateDropdown = (item) => {
        const filters = [];
        if (item.id === 'reset') {
            filters.push(paramsFilterDefault);
            setParamsFilter(filters);
        } else {
            const obj = {};
            obj[keyFilter] = item[valueFilter];
            item.filter = obj;
            filters.push(item.filter);
            setParamsFilter(filters);
        }
    };

    const updateDropdownTemporades = (item) => {
        const filters = [];
        if (concatFilterDefault) filters.push(paramsFilterDefault);
        const filtreOrdre = getOrdre(reglesTemporada, item.id, numTemporades);
        filtreOrdre?.map((filtre) => filters.push(filtre));
        filters.push({ temporada: item.id });
        setParamsFilter(filters);
        setActive(item.id);
    };

    const _getFilter = () => {
        if (tipus === 'seccions' && !__isEmpty(items)) {
            return (
                <Slider
                    estilModul={estilSlider}
                    items={_getItemsSeccions()}
                    tipus='seccions'
                    isFilter={true}
                    handlerFilter={handlerFilter}
                />
            );
        } else if (tipus === 'TN' && !__isEmpty(items)) {
            if (items[0].id != 'reset') {
                items.unshift({ id: 'reset', desc: 'Tots' });
            }
            return (
                <div className={styles.dropdown}>
                    <Dropdown
                        updateDropdown={updateDropdown}
                        items={items}
                        ariaLabel='Obrir desplegable edicions'
                        textSelector='Tots'
                    />
                </div>
            );
        } else if (tipus === 'temporades' && !__isEmpty(items)) {
            const temporades = _getListTemporades();
            if (componentFilterName === 'Selector') {
                const temporada = active?.includes('PUTEMP_') ? 'Temporada ' + active?.substring(7) : 'Selector temporades';
                return (
                    <div className={styles.dropdown}>
                        <Dropdown
                            ariaLabel='Obrir desplegable temporades'
                            updateDropdown={updateDropdownTemporades}
                            items={temporades}
                            textSelector={temporada}
                        />
                    </div>
                );
            } else {
                return <Slider items={temporades} tipus='seccions' isFilter={true} handlerFilter={handlerFilter} />;
            }
        }
    };

    const stylesHeader = clsx({
        [styles.titolCombo]: showTitol && componentFilterName === 'Selector',
        [styles.flexColumn]: showTitol?.positionTop && componentFilterName === 'Selector'
    });
    const stylesMosaic = clsx({
        [styles.mosaic]: true,
        [styles[estilMosaic]]: !__isEmpty(estilMosaic)
    });

    return (
        <>
            <div className={stylesHeader}>
                {(!__isEmpty(items) || itemsVideos.length > 0) && (
                    <TitolFilter {...props} nomPrograma={nomPrograma || routerQuery.slug} paginacio={paginacio} />
                )}
                <div className={styles.filter}>{_getFilter()}</div>
            </div>
            <div className={stylesMosaic}>
                {itemsVideos.length === 0 && (
                    <p data-testid='text-sense-capitols'>No tenim capítols disponibles d&lsquo;aquesta temporada</p>
                )}
                {divResultatsVideos}
            </div>
            {(!__isEmpty(items) || itemsVideos.length > 0) && (
                <BotoMes {...props} nomPrograma={nomPrograma || routerQuery.slug} filtreActiu={active} paginacio={paginacio} />
            )}
        </>
    );
}

FilterMosaic.defaultProps = {
    concatFilterDefault: true,
    loadMoreItemsScroll: true,
    numElementsDisplay: 8,
    noId: false
};
