import Boto from 'components/ui/Boto';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import styles from './filtermosaic.module.scss';

export default function BotoMes(props) {
    const { showButtonMoreItems, items, tipus, nomPrograma, filtreActiu, paginacio, numElementsDisplay } = props;
    if (!showButtonMoreItems || __isUndefined(paginacio) || paginacio?.total_items <= numElementsDisplay) return <></>;
    const typeLink = __isUndefined(showButtonMoreItems?.linkTipus)
        ? __isEmpty(items)
            ? 'web_3cat_programa_capitols_item'
            : 'web_3cat_programa_temporada_item'
        : showButtonMoreItems.linkTipus;

    const route =
        tipus === 'temporades'
            ? {
                  type: typeLink,
                  id: filtreActiu.replace('PUTEMP_', ''),
                  slug: nomPrograma,
                  slugRelacio: showButtonMoreItems?.slug2
              }
            : {
                  slug: showButtonMoreItems.slug,
                  slugRelacio: showButtonMoreItems.slug2,
                  type: showButtonMoreItems.linkTipus
              };
    const linkReady = __isEmpty(items) || filtreActiu !== '';

    return (
        linkReady && (
            <div className={styles.btnMoreItemsContent}>
                <Boto text='Tots' estil='transparent' mida='s' route={route} dataTestId={`boto-tots`} />
            </div>
        )
    );
}
