import { addParameterToUrl, getData, getUrlParameter, removeStorageValue, setStorageValue } from '@portal-internet/core';
import VirtualScroll from 'components/3cat/VirtualScroll';
import { useProducte } from 'hooks/3cat/useProducte';
import { useUser } from 'hooks/useUser';
import __get from 'lodash/get';
import __isEmpty from 'lodash/isEmpty';
import __reduce from 'lodash/reduce';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

const _getAllPagesItems = (data) => {
    if (data?.pageParams && data.pages?.length > 1 && typeof data.pageParams[data.pageParams?.length - 1] === 'object') {
        data = data.pages.shift();
    }

    return __reduce(
        __get(data, 'pages', []),
        (memo, { items }) => {
            return [...memo, ...items];
        },
        []
    );
};

export default function BlocDeContinguts(props) {
    let { paginacio, items, url, titolFirstTira, titolLogo } = props;
    const {
        tipus,
        format,
        nom,
        staleTime,
        prefetchedData,
        overwriteFirstTira,
        className,
        itemType,
        barraprogres,
        classNameMosaic,
        insertarComponent,
        routeType,
        slugRelacio,
        tema,
        mostraCodiEtic,
        mostrarNomPrograma,
        showLogoPrograma,
        normalizeItem,
        routerQuery,
        evaluatedCondicio,
        moduleName
    } = props;

    const { state: userState } = useUser();
    const { isWebviewActive } = useProducte();

    const router = useRouter();
    const field = format ? format : tipus;
    const queryKey = ['web-blocdecontinguts', { url, field, nom, moduleName }];

    const initialData =
        !__isEmpty(items) && paginacio
            ? {
                  pageParams: [undefined],
                  pages: [
                      {
                          items: items,
                          paginacio: paginacio
                      }
                  ]
              }
            : null;
    useEffect(() => {
        if (!isWebviewActive) removeStorageValue('web-fetch-page', false, '.3cat.cat');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            userState &&
            userState.router === router.pathname &&
            userState.namespace === '[web]items-usuari-afegeix-a-la-llista' &&
            userState.currentPage
        ) {
            loadMoreItems({ pageParam: { currentPage: userState.currentPage } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    const getCurrentUrl = (pageParam) => {
        if (typeof pageParam === 'number') return addParameterToUrl(url, 'pagina', pageParam);

        const itemsPagina = getUrlParameter(url, 'items_pagina');
        let currentUrl = addParameterToUrl(url, 'items_pagina', pageParam.currentPage * parseInt(itemsPagina));
        currentUrl = addParameterToUrl(currentUrl, 'pagina', 1);
        return currentUrl;
    };

    const fetchPage = async ({ pageParam = 1 }) => {
        const currentUrl = getCurrentUrl(pageParam);
        if (!isWebviewActive) {
            setStorageValue('web-fetch-page', pageParam, {
                path: '/',
                domain: '.3cat.cat',
                expires: 1
            });
        }
        const rawData = await getData({ queryKey: ['tira', { url: currentUrl, moduleName }] });
        const currentItems = __get(rawData, 'resposta.items.item', __get(rawData, 'canal', []));
        if (typeof pageParam === 'object') {
            const pag = __get(rawData, 'resposta.paginacio');
            const itemsPagina = getUrlParameter(url, 'items_pagina');
            return {
                items: currentItems,
                paginacio: {
                    total_items: currentItems.length,
                    items_pagina: currentItems.length,
                    pagina_actual: pageParam.currentPage,
                    total_pagines: Math.ceil(pag.total_items / itemsPagina)
                }
            };
        }
        return {
            items: currentItems,
            paginacio: __get(rawData, 'resposta.paginacio', {
                total_items: currentItems.length,
                items_pagina: currentItems.length,
                pagina_actual: 1,
                total_pagines: 1
            })
        };
    };

    const addLayoutComponent = (apiItems) => {
        if (insertarComponent === undefined) return [...apiItems];
        const nouComponent = {
            component: insertarComponent.name,
            tipus: 'CUSTOM',
            props: insertarComponent.props
        };
        let posicio = insertarComponent.posicio || apiItems.length;
        apiItems.splice(posicio, 0, nouComponent);
        return [...apiItems];
    };

    const getNextPageParam = (lastPage) => {
        const totalPages = lastPage.paginacio.total_pagines;
        const nextPage = lastPage.paginacio.pagina_actual + 1;
        return nextPage > totalPages ? undefined : nextPage;
    };

    const options = {
        staleTime,
        refetchOnMount: __isEmpty(items) ? 'always' : false,
        keepPreviousData: false,
        getNextPageParam,
        initialData
    };
    const { data: infiniteQueryData, fetchNextPage: loadMoreItems, hasNextPage } = useInfiniteQuery(queryKey, fetchPage, options);
    let apiItems = _getAllPagesItems(infiniteQueryData);

    apiItems = addLayoutComponent(apiItems);
    paginacio ??= __get(infiniteQueryData, 'pages.0.paginacio', 1);

    if (normalizeItem) apiItems = normalizeItem(apiItems);

    return apiItems && paginacio ? (
        <VirtualScroll
            apiItems={apiItems}
            loadMoreItems={loadMoreItems}
            hasNextPage={hasNextPage}
            prefetchedData={prefetchedData}
            overwriteFirstTira={overwriteFirstTira}
            titolFirstTira={titolFirstTira}
            titolLogo={titolLogo}
            tipus={tipus}
            className={className}
            barraprogres={barraprogres === undefined ? true : barraprogres}
            itemType={itemType}
            format={format}
            tema={tema}
            classNameMosaic={classNameMosaic}
            routeType={routeType}
            slugRelacio={slugRelacio}
            mostraCodiEtic={mostraCodiEtic}
            mostrarNomPrograma={mostrarNomPrograma}
            showLogoPrograma={showLogoPrograma}
            routerQuery={routerQuery}
            name={nom}
            router={router}
            evaluatedCondicio={evaluatedCondicio}
            urlTira={url}
            moduleName={moduleName}
        />
    ) : null;
}

BlocDeContinguts.defaultProps = {
    staleTime: Infinity,
    mostraCodiEtic: true,
    slugRelacio: '',
    moduleName: 'BlocDeContinguts'
};
