import Enllac from 'components/3cat/Enllac/Enllac';
import Icona from 'components/3cat/ui/Icona';
import __isUndefined from 'lodash/isUndefined';
import styles from './filtermosaic.module.scss';

export default function TitolFilter(props) {
    const { showTitol, nomPrograma, paginacio } = props;
    if (!showTitol || !showTitol.titol) return <></>;
    const { titol, linkTipus, linkSlugRelacio } = showTitol;

    return linkTipus && !__isUndefined(paginacio) && paginacio?.total_items > 0 ? (
        <>
            <Enllac
                slug={nomPrograma}
                type={linkTipus}
                className={`${styles.titol} ${styles.titolHover}`}
                titol={titol}
                slugRelacio={linkSlugRelacio}
                dataTestId='filter-mosaic-titol-enllac'
            ></Enllac>
            <Icona icona='fletxa_simple_dreta' alt='Següent' mida={18} className={styles.linktitol} ariaHidden={true} />
        </>
    ) : (
        <h2 className={styles.titolNoLink}>{titol}</h2>
    );
}
